import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ROLES from '@jsv2/config/userRoles';
import SIGNUP_FLOW from '@jsv2/config/SignUp/flows';
import SocialNetworks from '@components/Auth/SocialNetworks';
import { facebookSocialButton } from '@components/Auth/SocialButtons/Buttons';
import { isFacebookUrl } from '@jsv3/utils/urlUtils';
import SignUpContext from '@jsv2/context/SignUpContext';
import WizardContext from '@jsv2/context/WizardContext';
import UserContext from '@jsv2/context/UserContext';

const ProgressBar = ({ activeStep, handleSocialSignUp, signup }) => {
  const { stepConfig, getNextStep } = useContext(WizardContext);
  const { isPlanATripTemplate, signUpProcess } = useContext(SignUpContext);
  const { customer } = useContext(UserContext);
  const isDepositPaidFlow = signUpProcess.flow === SIGNUP_FLOW.DEPOSIT_PAID_FLOW;

  const hasIncompleteSignup = customer.roles.includes(ROLES.INCOMPLETE_SIGN_UP);
  const allowGoToCompleteStep = stepConfig.nextStep && !hasIncompleteSignup;

  const goToDetailsStep = () => {
    if (stepConfig.prevStep) {
      getNextStep(stepConfig.prevStep);
    }
  };

  const goToPaymentStep = () => {
    if (allowGoToCompleteStep) {
      getNextStep(stepConfig.nextStep);
    }
  };

  const renderFirstStep = () => {
    if (isFacebookUrl() && signup) {
      return (
        <div className="first-step__wrapper">
          <SocialNetworks
            onSuccess={handleSocialSignUp}
            buttons={facebookSocialButton}
            className="single-button"
          />

          <div className="first-step__or">or</div>

          <div className="first-step__title">Create an account using an email</div>
        </div>
      );
    }

    return (
      <div className="first-step__title">
        {isPlanATripTemplate && isDepositPaidFlow ? 'Sign Up' : 'Get Started'}
      </div>
    );
  };

  return (
    <div className={classNames('steps', `activeStep-${activeStep}`, { show: isFacebookUrl() })}>
      {activeStep === 1 ? (
        renderFirstStep()
      ) : (
        <>
          <div className="step-item item-1">{t('Signup')}</div>
          <div className="line line-1" />
          <button
            type="button"
            className="step-item item-2"
            data-qa-id="qa_detail_btn"
            onClick={goToDetailsStep}
          >
            {t('Details')}
          </button>
          <div className="line line-2" />
          <button
            type="button"
            disabled={!allowGoToCompleteStep}
            className="step-item item-3"
            data-qa-id="qa_complete_btn"
            onClick={goToPaymentStep}
          >
            {t('Complete')}
          </button>
        </>
      )}
    </div>
  );
};

ProgressBar.propTypes = {
  activeStep: PropTypes.number.isRequired,
  handleSocialSignUp: PropTypes.func,
  signup: PropTypes.bool,
};

ProgressBar.defaultProps = {
  handleSocialSignUp: () => {},
  signup: false,
};

export default ProgressBar;
