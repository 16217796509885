import React, { useState, useRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { getPaymentMethodListAPI } from '@jsv3/utils/api/userAPI';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import SignUpContext from '@jsv2/context/SignUpContext';
import { detectPremiumMembership } from '@jsv2/utils/flowUtils';
import Spinner from '@components/Spinner';
import TermsConditionsModal from '@jsv2/components/Modals/TermsConditionsModal';
import SavedPaymentMethods from '@components/checkout/SecureCheckout/SavedPaymentMethods';
import CreditCardDataInput from '../../PaymentStepDefault/FormParts/CreditCardDataInput';

const { user } = window;

const AddCreditCardForm = ({ showNameOnCardField, onClose, onSubmit, errors }) => {
  const { signUpProcess, urlParamsData } = useContext(SignUpContext);
  const { flow, membershipSummary } = signUpProcess;
  const { promoCampaignId, name } = membershipSummary;

  const [selectedPaymentId, setSelectedPaymentId] = useState(null);
  const [isTermsChecked, setIsTermsChecked] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userSavedPaymentMethods, setUserSavedPaymentMethods] = useState([]);
  const [isPaymentMethodsLoading, setIsPaymentMethodsLoading] = useState(false);

  const decodedUrlParamsData = urlParamsData ? JSON.parse(atob(urlParamsData)) : {};

  const inputRef = useRef(null);

  useEffect(() => {
    if (user) {
      setIsPaymentMethodsLoading(true);

      getPaymentMethodListAPI()
        .then(({ data }) => {
          setUserSavedPaymentMethods(data);
          setSelectedPaymentId(data.length > 0 ? data[0].payment_method_id : null);
        })
        .finally(() => setIsPaymentMethodsLoading(false));
    }
  }, []);

  const getPaymentData = () => {
    const cardData = inputRef.current.getData();
    // eslint-disable-next-line camelcase
    const card_exp_year =
      +cardData.expirationYear < 100 ? `20${cardData.expirationYear}` : cardData.expirationYear;
    cardData.save_method = true;

    Object.assign(
      cardData,
      { card_exp_year },
      { promo_campaign_id: promoCampaignId },
      { signup_flow: flow },
      { plan_a_trip_quiz: decodedUrlParamsData },
    );

    return cardData;
  };

  const onHandleSubmit = () => {
    if (selectedPaymentId !== null) {
      const selectedPaymentData = userSavedPaymentMethods.find(
        (method) => method.payment_method_id === selectedPaymentId,
      );

      onSubmit(selectedPaymentData);
    } else {
      onSubmit(getPaymentData());
    }
  };

  const onPaymentMethodChange = (method) => {
    if (typeof method === 'string') {
      setSelectedPaymentId(null);
    } else {
      setSelectedPaymentId(method.payment_method_id);
    }
  };

  const renderSavedMethods = () => (
    <SavedPaymentMethods
      selectedMethodId={selectedPaymentId}
      savedMethods={userSavedPaymentMethods}
      onPaymentMethodChange={onPaymentMethodChange}
    />
  );

  const renderFullCardForm = () => {
    const isCreditCardActive = selectedPaymentId === null;

    return (
      <>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className={classnames('credit-card-icon', {
            'active-method': isCreditCardActive,
          })}
          data-qa-id="pay_with_card"
          onClick={() => setSelectedPaymentId(null)}
        >
          <img
            src={`/images/icons/card-payment-${isCreditCardActive ? 'white' : 'pink'}.svg`}
            alt="card"
          />
          <span>credit card</span>
        </div>

        <div className="divider" />

        {renderSavedMethods()}

        <CreditCardDataInput
          errors={errors}
          visible={isCreditCardActive}
          ref={inputRef}
          showNameOnCardField={showNameOnCardField}
        />
      </>
    );
  };

  const renderTermsAndConditions = () => (
    <div className="terms-and-conditions custom-checkbox">
      <input
        id="terms-and-conditions-check"
        type="checkbox"
        checked={isTermsChecked}
        onChange={() => setIsTermsChecked((prevState) => !prevState)}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
      <label htmlFor="terms-and-conditions-check">
        <div>I accept the&nbsp;</div>
      </label>

      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="button-link"
        onClick={() => setIsModalOpen(true)}
        data-qa-id="accept_checkbox"
      >
        terms and conditions
      </div>
    </div>
  );

  return (
    <>
      <div className="adding-credit-card" data-qa-id="adding-credit-card">
        {isPaymentMethodsLoading ? <Spinner /> : renderFullCardForm()}

        {renderTermsAndConditions()}
      </div>

      <div className="content-footer text-center">
        {onClose && (
          <button
            onClick={() => onClose()}
            type="button"
            className="mr-10"
            data-qa-id="cancel-button"
          >
            {t('Cancel')}
          </button>
        )}
        <button
          onClick={onHandleSubmit}
          type="button"
          className="button-pink"
          disabled={!isTermsChecked}
          data-qa-id="join_vip_traveler_button"
        >
          {detectPremiumMembership(flow) ? 'Get VIP+ Membership' : `JOIN ${name}`}
        </button>
      </div>

      <TermsConditionsModal isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} />
    </>
  );
};

AddCreditCardForm.propTypes = {
  showNameOnCardField: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

AddCreditCardForm.defaultProps = {
  showNameOnCardField: false,
  onClose: null,
  errors: {},
};

export default ErrorBoundaryDecorator()(AddCreditCardForm);
